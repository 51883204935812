import React, { useEffect, useState, useRef } from "react";
import { Table, Button, Space, Divider, Switch, Popconfirm } from "antd";
import "./Catalog.css";
import { useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../database/firebase";
import { Accordion, Icon, Popup, Button as BS } from "semantic-ui-react";
import { arrayUnion } from "firebase/firestore";
import ScutireDisplay from "./ScutireDisplay";
import Docxtemplater from "docxtemplater";
import { renderClassName } from "../utils";
import catalogGimanziu from "./catalog_gimnaziu.docx";
import { motiveazaAbsente } from "../utils/absente";
import { Alert } from "antd";

import PizZip from "pizzip";
import { saveAs } from "file-saver";
import template from "./instiintare parinti 2024 completat.docx";

import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size";
import { getDataDoc } from "../database";
import { updateDocDatabase } from "../database";
import ModalAddGrade from "./ModalAddGrade";
import { Table as CatalogTabel } from "semantic-ui-react";
import ModalViewGrade from "./ModalViewGrade";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import { openSuccesNotification } from "../Components/Notifications/succesNotification";
import {
  calculare_medii,
  calculare_medii_incheire_cursuri,
  calculeaza_medie_materie,
} from "../utils/calculare_medie";
import { exportExcel } from "./excelexport";
import { openErrorNotification } from "./Notifications/errorNotification";
import { maxHeightIcon } from "@progress/kendo-svg-icons";

function Catalog({ classData, setClassData, mode = "edit", permision }) {
  const [eleviData, setEleviData] = useState([]);
  const componentRef = useRef();
  const onlyWidth = useWindowWidth();
  const [open, setOpen] = useState(false);

  const [gradesElevi, setGradesElevi] = useState([]);
  const [elevId, setElevId] = useState();
  const [elevId2, setElevId2] = useState();
  const [teza, setTeza] = useState();
  const [materieId, setMaterieId] = useState();
  const print = useRef();
  const [nota, setNota] = useState();
  const [inchideMediiAutomat, setInchideMediiAutomat] = useState(false);
  const [allData, setAllData] = useState({});
  const profesori = useSelector((state) => state.profesori);
  const settings = useSelector((state) => state.settings);
  const Limit = 40;
  const [deleted, setDeleted] = useState("");
  const [motivStergereMedie, setMotivStergereMedie] = useState("");
  const [notePrint, setNotePrint] = useState([]);
  const [author, setAuthor] = useState("");
  const navigate = useNavigate();
  const [comentariu, setComentariu] = useState();
  const [edit, setEdit] = useState(false);
  const clase = useSelector((state) => state.clase);

  const [faraNote, setFaraNote] = useState(false);
  const windowSize = useRef(window.innerWidth);
  const [data, setData] = useState();
  const user = useSelector((state) => state.user);

  const [tip, setTip] = useState();
  const [entity, setEntity] = useState({});
  const [scutiri, setScutiri] = useState({});
  const [allNotes, setAllNotes] = useState([]);
  const [display, setDisplay] = useState(false);

  const [id, setId] = useState("");
  const [profileElevi, setProfileElevi] = useState({});
  const [open2, setOpen2] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const secondColumn = 12;
  const pref = [
    "Limba și literatura română",
    "Limba Engleză",
    "Limba Italiană",
    "Matematică",
    "Fizică",
    "Chimie",
    "Biologie",
    "Istorie",
    "Geografie",
    "Logică",
    "Religie",
    "Arte vizuale și abilități practice",
    "Educație fizică",
    "Teorie-solfegiu-dicteu",
    "Tehnologia informației și a comunicațiilor",
    "Etnografie și folclor muzical",
    "Muzică vocală tradițională românească",
    "Instrument la alegere - Vioară",
    "Instrument la alegere - Chitară",
    "Instrument la alegere - Clarinet",
    "Instrument la alegere - Flaut",
    "Instrument la alegere - Contrabas",
    "Corepetiție",
    "Ansamblu folcloric",
    "Istoria Muzicii",
    "Psihologie",
    "Educație Anteprenorială",
    "Economie",
    "Armonie",
    "Forme muzicale",
    "Muzică de cameră",
    "Consiliere și orientare / Purtare",
  ];

  function numarInLitere(numar) {
    if (numar === false) return "";
    if (numar === "" || numar === "undefined" || numar === undefined) return "";
    if (numar === "FB") return "(Foarte bine)";
    if (numar === "B") return "(Bine)";
    if (numar === "I") return "(Insuficient)";
    if (numar === "S") return "(Suficient)";
    if (
      numar < 0 ||
      numar > 10 ||
      !/^(\d+)(\.\d{2})?$/.test(numar.toFixed(2))
    ) {
      return "Număr invalid. Introduceți un număr între 0 și 10 cu două zecimale sau fără.";
    }

    const intregi = [
      "zero",
      "unu",
      "doi",
      "trei",
      "patru",
      "cinci",
      "șase",
      "șapte",
      "opt",
      "nouă",
      "zece",
    ];

    const zecimale = [
      "zero",
      "unu",
      "doi",
      "trei",
      "patru",
      "cinci",
      "șase",
      "șapte",
      "opt",
      "nouă",
    ];

    const [parteaIntreaga, parteaZecimala] = numar.toFixed(2).split(".");

    let rezultat = intregi[parseInt(parteaIntreaga, 10)];

    if (parteaZecimala && parseInt(parteaZecimala) > 0) {
      let zecimaleText = [
        zecimale[parseInt(parteaZecimala[0], 10)],
        zecimale[parseInt(parteaZecimala[1], 10)],
      ];
      rezultat += " și " + zecimaleText.join(" ");
    }

    return "(" + rezultat + ")";
  }

  // Exemple de utilizare:

  function groupArrayIntoChunks(array, chunkSize = 3) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }

  const fetchTemplate = async () => {
    const response = await fetch(catalogGimanziu);
    return await response.arrayBuffer();
  };

  function modifyDocumentXML(zip) {
    const xmlPath = "word/document.xml";
    let documentXml = zip.file(xmlPath).asText();

    // Parsează XML-ul într-un DOM
    const parser = new DOMParser();
    const serializer = new XMLSerializer();
    const xmlDoc = parser.parseFromString(documentXml, "application/xml");

    // Găsește toate nodurile <w:t> (text)
    const textNodes = xmlDoc.getElementsByTagName("w:t");

    for (let i = 0; i < textNodes.length; i++) {
      const node = textNodes[i];
      const textContent = node.textContent;

      if (textContent.startsWith("adauga_bordura_")) {
        // Scoate prefixul "adauga_bordura_"
        const cleanText = textContent.replace("adauga_bordura_", "");

        // Creează un nou nod <w:r> care include bordurile și textul curat
        const newRun = xmlDoc.createElement("w:r");

        const runProperties = xmlDoc.createElement("w:rPr");
        newRun.appendChild(runProperties);

        const newTextNode = xmlDoc.createElement("w:t");
        newTextNode.textContent = cleanText;

        newRun.appendChild(newTextNode);

        // Creează un paragraf (<w:p>) cu borduri
        const newParagraph = xmlDoc.createElement("w:p");
        const paragraphProperties = xmlDoc.createElement("w:pPr");
        const borders = xmlDoc.createElement("w:pBdr");

        ["top", "left", "bottom", "right"].forEach((side) => {
          const border = xmlDoc.createElement(`w:${side}`);
          border.setAttribute("w:val", "single");
          border.setAttribute("w:sz", "4");
          border.setAttribute("w:space", "0");
          border.setAttribute("w:color", "000000");
          borders.appendChild(border);
        });

        paragraphProperties.appendChild(borders);
        newParagraph.appendChild(paragraphProperties);
        newParagraph.appendChild(newRun);

        // Înlocuiește doar nodul text original cu noul paragraf
        const originalParent = node.parentNode.parentNode;
        originalParent.parentNode.replaceChild(newParagraph, originalParent);
      }
    }

    // Serializează XML-ul modificat înapoi într-un string
    documentXml = serializer.serializeToString(xmlDoc);

    // Actualizează fișierul în arhiva ZIP
    zip.file(xmlPath, documentXml);

    return zip;
  }

  async function generateDocument() {
    try {
      const templateArrayBuffer = await fetchTemplate();
      const zip = new PizZip(templateArrayBuffer);

      const templateDoc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });
      let cnt = 1;
      const listOfProfesori = allNotes.reduce((acc, nota) => {
        if (
          !profesori.find((p) => p.id === nota.authorId) ||
          acc.find(
            (c) =>
              c.materie ===
                materii.find((m) => m.id === nota.materieId).numeMaterie &&
              c.profesor ===
                (profesori.find((p) => p.id === nota.authorId)
                  ? profesori.find((p) => p.id === nota.authorId)
                      .numeDeFamilie +
                    " " +
                    profesori.find((p) => p.id === nota.authorId).numeDeFamilie
                  : nota.author)
          )
        )
          return acc;
        else
          return [
            ...acc,
            {
              materie: materii.find((m) => m.id === nota.materieId).numeMaterie,
              profesor: profesori.find((p) => p.id === nota.authorId)
                ? profesori.find((p) => p.id === nota.authorId).numeDeFamilie +
                  " " +
                  profesori.find((p) => p.id === nota.authorId).numeDeFamilie
                : nota.author,
            },
          ];
      }, []);
      cnt = 0;

      let profesoriTabel = listOfProfesori.reduce(
        (acc, p) => {
          ++cnt;
          return {
            ...acc,
            ["tab_d" + cnt]: p.materie,
            ["tab_p" + cnt]: p.profesor,
          };
        },
        {
          tab_d1: "",
          tab_p1: "",
          tab_d2: "",
          tab_p2: "",
          tab_d3: "",
          tab_p3: "",
          tab_d4: "",
          tab_p4: "",
          tab_d5: "",
          tab_p5: "",
          tab_d6: "",
          tab_p6: "",
          tab_d7: "",
          tab_p7: "",
          tab_d8: "",
          tab_p8: "",
          tab_d9: "",
          tab_p9: "",
          tab_d10: "",
          tab_p10: "",
          tab_d11: "",
          tab_p11: "",
          tab_d12: "",
          tab_p12: "",
          tab_d13: "",
          tab_p13: "",
          tab_d14: "",
          tab_p14: "",
          tab_d15: "",
          tab_p15: "",
          tab_d16: "",
          tab_p16: "",
          tab_d17: "",
          tab_p17: "",
          tab_d18: "",
          tab_p18: "",
          tab_d19: "",
          tab_p19: "",
          tab_d20: "",
          tab_p20: "",
          tab_d21: "",
          tab_p21: "",
          tab_d22: "",
          tab_p22: "",
          tab_d23: "",
          tab_p23: "",
          tab_d24: "",
          tab_p24: "",
          tab_d25: "",
          tab_p25: "",
          tab_d26: "",
          tab_p26: "",
          tab_d27: "",
          tab_p27: "",
          tab_d28: "",
          tab_p28: "",
          tab_d29: "",
          tab_p29: "",
          tab_d30: "",
          tab_p30: "",
        }
      );
      cnt = 0;

      let materiiCatalog = classData.materii.reduce((acc, m) => {
        ++cnt;
        return {
          ...acc,
          ["m" + cnt]: materii.find((mm) => mm.id === m.materie)?.numeMaterie,
        };
      }, {});
      cnt = 0;
      const formatDate = (today) => {
        const romaniaTime = new Date(
          today.toLocaleString("en-US", { timeZone: "Europe/Bucharest" })
        );

        const yyyy = romaniaTime.getFullYear();
        let mm = romaniaTime.getMonth() + 1; // Months start at 0!
        let dd = romaniaTime.getDate();
        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        return dd + "." + mm;
      };
      function createFractionMathML(nota, date) {
        return `
          <w:r>
            <m:oMath>
              <m:f>
                <m:num>
                  <m:r>
                    <m:t>${nota}</m:t>
                  </m:r>
                </m:num>
                <m:den>
                  <m:r>
                    <m:t>${date}</m:t>
                  </m:r>
                </m:den>
              </m:f>
            </m:oMath>
          </w:r>
        `;
      }
      cnt = 0;
      let dateElevi = eleviData
        .sort((a, b) => {
          return a?.nume?.localeCompare(b.nume, "ro");
        })
        .reduce((acc, e) => {
          ++cnt;
          return {
            ...acc,
            ["e" + cnt + "_nf"]: e.numeDeFamilie,
            ["e" + cnt + "_pr"]: e.prenume,
            ["e" + cnt + "_med1"]: calculare_medii_incheire_cursuri(
              gradesElevi[e.id]?.note || [],
              materii,
              scutiri[e.id]
            ),
            ["e" + cnt + "_med2"]: calculare_medii(
              gradesElevi[e.id]?.note || [],
              materii,
              scutiri[e.id]
            ),
            ["e" + cnt + "_medg"]: calculare_medii(
              gradesElevi[e.id]?.note || [],
              materii,
              scutiri[e.id]
            ),
            ["e" + cnt + "_detalii"]: e.detalii || "",
            ...gradesElevi[e.id].absente.absente_dupa_motivari?.reduce(
              (acc, n) => {
                let indexMaterie =
                  classData.materii.findIndex(
                    (a) => a.materie === n.materieId
                  ) + 1;
                if (n.tip === "absenta")
                  return {
                    ...acc,
                    ["e" + cnt + "_a" + "_m" + indexMaterie]: [
                      ...(acc?.["e" + cnt + "_a" + "_m" + indexMaterie] || []),
                      {
                        text: " ",
                      },
                      {
                        text:
                          n.motivat === true
                            ? "adauga_bordura_" + formatDate(new Date(n.date))
                            : formatDate(new Date(n.date)),
                      },
                    ],
                  };
                if (n.tip === "nota")
                  return {
                    ...acc,
                    ["e" + cnt + "_n" + "_m" + indexMaterie]: [
                      ...(acc?.["e" + cnt + "_n" + "_m" + indexMaterie] || []),
                      {
                        text: " ",
                      },
                      {
                        text: n.nota + "/" + formatDate(new Date(n.date)),
                      },
                    ],
                  };
              },
              {
                ["e" + cnt + "_a" + "_m" + 1]: [],
                ["e" + cnt + "_a" + "_m" + 2]: [],
                ["e" + cnt + "_a" + "_m" + 3]: [],
                ["e" + cnt + "_a" + "_m" + 4]: [],
                ["e" + cnt + "_a" + "_m" + 5]: [],
                ["e" + cnt + "_a" + "_m" + 6]: [],
                ["e" + cnt + "_a" + "_m" + 7]: [],
                ["e" + cnt + "_a" + "_m" + 8]: [],
                ["e" + cnt + "_a" + "_m" + 9]: [],
                ["e" + cnt + "_a" + "_m" + 10]: [],
                ["e" + cnt + "_a" + "_m" + 11]: [],
                ["e" + cnt + "_a" + "_m" + 12]: [],
                ["e" + cnt + "_a" + "_m" + 13]: [],
                ["e" + cnt + "_a" + "_m" + 14]: [],
                ["e" + cnt + "_a" + "_m" + 15]: [],
                ["e" + cnt + "_a" + "_m" + 16]: [],
                ["e" + cnt + "_a" + "_m" + 17]: [],
                ["e" + cnt + "_a" + "_m" + 18]: [],
                ["e" + cnt + "_a" + "_m" + 19]: [],
                ["e" + cnt + "_a" + "_m" + 20]: [],
                ["e" + cnt + "_a" + "_m" + 21]: [],
                ["e" + cnt + "_a" + "_m" + 22]: [],
                ["e" + cnt + "_a" + "_m" + 23]: [],
                ["e" + cnt + "_a" + "_m" + 24]: [],
                ["e" + cnt + "_a" + "_m" + 25]: [],
                ["e" + cnt + "_a" + "_m" + 26]: [],
                ["e" + cnt + "_a" + "_m" + 27]: [],
              }
            ),
            ...gradesElevi[e.id].note?.reduce(
              (acc, n) => {
                let indexMaterie =
                  classData.materii.findIndex(
                    (a) => a.materie === n.materieId
                  ) + 1;

                if (n.tip === "nota") {
                  return {
                    ...acc,
                    ["e" + cnt + "_n" + "_m" + indexMaterie]: [
                      ...(acc?.["e" + cnt + "_n" + "_m" + indexMaterie] || []),
                      {
                        text: " ",
                      },
                      {
                        text: n.nota + "/" + formatDate(new Date(n.date)),
                      },
                    ],
                  };
                }
                return acc;
              },
              {
                ["e" + cnt + "_n" + "_m" + 1]: [],
                ["e" + cnt + "_n" + "_m" + 2]: [],
                ["e" + cnt + "_n" + "_m" + 3]: [],
                ["e" + cnt + "_n" + "_m" + 4]: [],
                ["e" + cnt + "_n" + "_m" + 5]: [],
                ["e" + cnt + "_n" + "_m" + 6]: [],
                ["e" + cnt + "_n" + "_m" + 7]: [],
                ["e" + cnt + "_n" + "_m" + 8]: [],
                ["e" + cnt + "_n" + "_m" + 9]: [],
                ["e" + cnt + "_n" + "_m" + 10]: [],
                ["e" + cnt + "_n" + "_m" + 11]: [],
                ["e" + cnt + "_n" + "_m" + 12]: [],
                ["e" + cnt + "_n" + "_m" + 13]: [],
                ["e" + cnt + "_n" + "_m" + 14]: [],
                ["e" + cnt + "_n" + "_m" + 15]: [],
                ["e" + cnt + "_n" + "_m" + 16]: [],
                ["e" + cnt + "_n" + "_m" + 17]: [],
                ["e" + cnt + "_n" + "_m" + 18]: [],
                ["e" + cnt + "_n" + "_m" + 19]: [],
                ["e" + cnt + "_n" + "_m" + 20]: [],
                ["e" + cnt + "_n" + "_m" + 21]: [],
                ["e" + cnt + "_n" + "_m" + 22]: [],
                ["e" + cnt + "_n" + "_m" + 23]: [],
                ["e" + cnt + "_n" + "_m" + 24]: [],
                ["e" + cnt + "_n" + "_m" + 25]: [],
                ["e" + cnt + "_n" + "_m" + 26]: [],
                ["e" + cnt + "_n" + "_m" + 27]: [],
              }
            ),
            ...classData.materii.reduce((acc, m) => {
              return {
                ...acc,
                ["e" +
                cnt +
                "_m" +
                (classData.materii.findIndex((f) => f.materie === m.materie) +
                  1)]:
                  (calculeaza_medie_materie(
                    gradesElevi[e.id]?.note || [],
                    materii.find((f) => f.id === m.materie),
                    scutiri[e.id]
                  ).medieIntiala ||
                    calculeaza_medie_materie(
                      gradesElevi[e.id]?.note || [],
                      materii.find((f) => f.id === m.materie),
                      scutiri[e.id]
                    ).medie) +
                  numarInLitere(
                    calculeaza_medie_materie(
                      gradesElevi[e.id]?.note || [],
                      materii.find((f) => f.id === m.materie),
                      scutiri[e.id]
                    ).medieIntiala ||
                      calculeaza_medie_materie(
                        gradesElevi[e.id]?.note || [],
                        materii.find((f) => f.id === m.materie),
                        scutiri[e.id]
                      ).medie
                  ),

                ["e" +
                cnt +
                "_mc" +
                (classData.materii.findIndex((f) => f.materie === m.materie) +
                  1)]:
                  ((gradesElevi[e.id]?.note || [],
                  materii.find((f) => f.id === m.materie),
                  scutiri[e.id])?.corigenta === undefined
                    ? ""
                    : calculeaza_medie_materie(
                        gradesElevi[e.id]?.note || [],
                        materii.find((f) => f.id === m.materie),
                        scutiri[e.id]
                      )?.corigenta?.corigenta) +
                  numarInLitere(
                    calculeaza_medie_materie(
                      gradesElevi[e.id]?.note || [],
                      materii.find((f) => f.id === m.materie),
                      scutiri[e.id]
                    )?.corigenta === undefined
                      ? ""
                      : calculeaza_medie_materie(
                          gradesElevi[e.id]?.note || [],
                          materii.find((f) => f.id === m.materie),
                          scutiri[e.id]
                        )?.corigenta?.corigenta
                  ),
                ["e" +
                cnt +
                "_a" +
                (classData.materii.findIndex((f) => f.materie === m.materie) +
                  1)]:
                  (calculeaza_medie_materie(
                    gradesElevi[e.id]?.note || [],
                    materii.find((f) => f.id === m.materie),
                    scutiri[e.id]
                  ).medie || "") +
                  numarInLitere(
                    calculeaza_medie_materie(
                      gradesElevi[e.id]?.note || [],
                      materii.find((f) => f.id === m.materie),
                      scutiri[e.id]
                    ).medie || ""
                  ),
              };
            }, {}),
          };
        }, {});
      cnt = 0;
      eleviData
        .sort((a, b) => {
          return a?.nume?.localeCompare(b.nume, "ro");
        })
        .forEach((e) => {
          ++cnt;
          let max1 = 0;
          for (let i = 1; i <= 11; ++i) {
            max1 = Math.max(
              max1,

              dateElevi["e" + cnt + "_a" + "_m" + i]?.length || 0
            );
          }
          let max2 = 0;
          for (let i = 12; i <= 23; ++i) {
            max2 = Math.max(
              max2,

              dateElevi["e" + cnt + "_a" + "_m" + i]?.length || 0
            );
          }

          if (max1 > max2) {
            for (let i = 12; i <= 23; ++i) {
              while (dateElevi["e" + cnt + "_a" + "_m" + i].length < max1) {
                dateElevi["e" + cnt + "_a" + "_m" + i].push({ text: "" });
              }
            }
          } else {
            for (let i = 1; i <= 11; ++i) {
              while (dateElevi["e" + cnt + "_a" + "_m" + i].length < max2) {
                dateElevi["e" + cnt + "_a" + "_m" + i].push({ text: "" });
              }
            }
          }
        });

      const resume = {
        ui: settings.numeInstitutie,
        loc: settings.loc,
        judet: settings.judet,
        clasa: renderClassName(clase.find((c) => c.id === classData.id)),
        director: settings.director,
        dir:
          profesori.find((p) => p.id === classData.diriginte).numeDeFamilie +
          " " +
          profesori.find((p) => p.id === classData.diriginte).prenume,
        ...profesoriTabel,

        ...materiiCatalog,
        ...dateElevi,
      };

      templateDoc.render(resume);

      const generatedDoc = templateDoc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        compression: "DEFLATE",
      });
      const zipFromGeneratedDoc = new PizZip(await generatedDoc.arrayBuffer());

      // Modifică XML-ul documentului
      const modifiedZip = modifyDocumentXML(zipFromGeneratedDoc);

      // Generează documentul final cu modificările aplicate
      const finalGeneratedDoc = modifiedZip.generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        compression: "DEFLATE",
      });

      // Salvează documentul final
      saveAs(finalGeneratedDoc, `Export Catalog Electronic.docx`);
    } catch (error) {
      console.error("Error generating document:", error.message);
    }
  }

  const styleD = () => {
    if (onlyWidth < 700) return "auto";
    if (onlyWidth < 1000) return "auto auto ";
    if (onlyWidth < 1200) return "auto auto auto ";
    return "auto auto auto  ";
  };

  const fetchData = async () => {
    let newArray = [];
    let scutiriElevi = {};
    let note = {};
    let NotePrint = [];

    for (let elev of classData?.elevi || []) {
      const docs = await getDataDoc("eleviDocumente", elev.id);

      scutiriElevi[elev.id] = docs?.docsElev.filter(
        (doc) => doc.tip === "scutire" || doc.tip === "bilet"
      );
    }
    let allNotes = [];
    for await (let elev of classData?.elevi || []) {
      let obj = {};
      const notes = await getDataDoc("catalog", elev.id);
      //   if (elev.mutat === true) {
      //     note[elev.id] = {
      //       note: elev.gradesFrozen,
      //       absente: motiveazaAbsente(
      //         elev.gradesFrozen,
      //         elev?.docsFrozen?.docsElev
      //       ),
      //     };
      //   } else
      note[elev.id] = {
        note: notes?.note,
        absente: motiveazaAbsente(notes?.note, scutiriElevi[elev.id]),
      };
      allNotes = [
        ...allNotes,
        ...(notes?.note || []),
        ...(motiveazaAbsente(notes?.note, scutiriElevi[elev.id])
          .absente_dupa_motivari || []),
      ];
      let dataE = await getDataDoc("elevi", elev.id);
      newArray.push({
        ...elev,
        ...dataE,
        key: elev.id,
        nume: elev.numeDeFamilie + " " + elev.initiala + " " + elev.prenume,
      });
    }

    setNotePrint(NotePrint);

    setScutiri(scutiriElevi);
    setEleviData(newArray);
    setGradesElevi(note);
    setAllNotes(allNotes);
  };
  const decideNumber = () => {
    if (onlyWidth < 700) return 1;
    if (onlyWidth < 900) return 2;
    if (onlyWidth < 1500) return 3;

    return 4;
  };
  const groupArray = (array) => {
    let newArray = [[]];
    let index = 0;
    (array || []).forEach((e) => {
      if (newArray[index].length < decideNumber()) newArray[index].push(e);
      else newArray[++index] = [e];
    });
    return newArray;
  };
  const formatDate = (today) => {
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    return dd + "." + mm;
  };
  useEffect(() => {
    fetchData();
  }, [classData]);
  useEffect(() => {
    let array = [];
    for (let elev of classData?.elevi || []) {
      const unsub = onSnapshot(doc(db, "catalog", elev.id), (doc) => {
        fetchData();
      });
      const unsub2 = onSnapshot(doc(db, "eleviDocumente", elev.id), (doc) => {
        fetchData();
      });
      array.push(unsub);
      array.push(unsub2);
    }
  }, []);

  const fetchProfiles = async () => {
    let profileElevi = {};
    for await (let elev of classData?.elevi || []) {
      const profilElev = await getDataDoc("elevi", elev.id);
      profileElevi[elev.id] = profilElev;
    }
    setProfileElevi(profileElevi);
  };
  useEffect(() => {
    if (display === true) {
      fetchProfiles();
    }
  }, [display]);

  const materii = useSelector((state) => state.materii);
  const handlePrint = useReactToPrint({
    content: () => print.current,
  });

  return (
    <>
      <h3>Catalogul este gata</h3>
      <Button
        onClick={() => {
          generateDocument();
        }}
      >
        Print
      </Button>
    </>
  );
}

export default Catalog;
